

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormGroup, Select, Checkbox, ModalOverlay } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import { IconWarningRounded } from '@punchcard/core/icons';

interface ITransferModalProps {
	showTransferModal: boolean;
	currentStudent?: AdminSchoolUserDTO;
	schoolList: AdminSchoolDTO[];
	closeModal: () => void;
	transferStudent: (studentId: number, newSchoolId: number) => void;
}

interface ITransfer {
	studentId: number | undefined;
	oldSchoolId: number | undefined;
	newSchoolId: number | undefined;
	studentName: string;
	currentSchoolName: string;
	confirm: boolean;
}

const TransferModal = (props: ITransferModalProps) => {
	const { showTransferModal, closeModal, currentStudent } = props;
	const { t } = useTranslation();
	const options = props.schoolList
		.filter((school) => {
			return school.id !== currentStudent?.schoolId;
		})
		.map((item) => {
			return { value: item.id, label: item.schoolName };
		});

	const methods = useForm<ITransfer>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	React.useEffect(() => {
		methods.reset({
			oldSchoolId: currentStudent?.schoolId,
			newSchoolId: 0,
			studentId: currentStudent?.id,
			studentName: currentStudent?.firstName + ' ' + currentStudent?.lastName,
			currentSchoolName: currentStudent?.schoolName,
			confirm: false,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStudent]);

	const newSchoolId = methods.watch('newSchoolId');
	const confirm = methods.watch('confirm');

	const { control } = methods;


	const onSubmit = () => {
		const data = methods.getValues();
		props.transferStudent(data.studentId ?? 0, data.newSchoolId ?? 0);
	};
	if (showTransferModal === false) {
		return null;
	}

	return (
		<ModalOverlay
			isOpen={showTransferModal}
			modalSize="md"
			onRequestClose={closeModal}
			headerChildren={t('transfer.transfer_student')}
			confirmButtonChildren={t('transfer.confirm_transfer')}
			cancelButtonChildren={t('cancel')}
			confirmButtonAction={() => {
				onSubmit();
			}}
			cancelButtonAction={() => {
				closeModal();
			}}
			disableConfirmButton={!newSchoolId || !confirm}
			hideCloseButton={true}
			shouldCloseOnOverlayClick={false}
		>
			<div className="bg-warning-100 d-flex p-2 rounded-3" role="alert">
				<div className="me-2">
					<IconWarningRounded className="text-warning-600" style={{ width: 27, height: 33 }} />
				</div>
				<div className="text-warning-800">
					{t('transfer.transfer_student_warning')}
				</div>
			</div>
			<div className="row mt-2">
				<label className="form-label">{t('transfer.transfer_details')}</label>
				<p className="mb-0">{`${t('transfer.student')} ${currentStudent?.firstName} ${currentStudent?.lastName}  `}</p>
				<p>{`${t('transfer.current_school')} ${currentStudent?.schoolName} `}</p>
			</div>
			<Controller
				name={'newSchoolId'}
				control={control}
				key={'newSchool'}
				render={({ field, fieldState }) => {
					return (
						<FormGroup label={t('transfer.transfer_new_school')} field={field} fieldState={fieldState} required={true} >
							<Select
								value={field.value}
								onChange={field.onChange}
								options={options}
								error={!!fieldState.error?.message} />
						</FormGroup>
					);
				}} />
			<Controller
				name={'confirm'}
				control={control}
				key={'confirm'}
				render={({ field, fieldState }) => {
					return (
						<FormGroup field={field} fieldState={fieldState}>
							<Checkbox
								checked={field.value}
								onChange={field.onChange}
								label={t('transfer.confirm_transfer_understand')}

							/>
						</FormGroup>
					);
				}} />
		</ModalOverlay>


	);
};

export default TransferModal;
