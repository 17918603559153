import React from 'react';
import { Button, DataTable, LoadingPage, Page, PageHero, ActionIcon, setStatusCellValue, filterByStatus, StatusFilterMethod } from '@punchcard/core';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import { IconAdd, IconEdit } from '@punchcard/core/icons';
import { RowInfo } from 'react-table';

const UsersList = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [schoolUserList, setSchoolUserList] = React.useState<AdminSchoolUserDTO[]>([]);
	const [loading, setLoading] = React.useState<boolean>(true);

	React.useEffect(() => {
		async function getSchoolList() {
			const schools = await adminAPI.school.getTenantUserList(1);
			if (schools !== null) {
				setSchoolUserList(schools.schoolUsers);
			} else {
				toast.error(t('unable_to_retrieve_schoo_user_list'));
			}
			setLoading(false);
		}
		getSchoolList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openAdminlUser = (row: RowInfo) => {
		navigate(`/admin/users/${row.original.id}`);
	};

	const newUser = async () => {
		navigate('/admin/users/0');
	};

	const columns = [
		{
			Header: 'User ID',
			accessor: 'id',
			show: false
		},
		{
			Header: t('adminUsers.name'),
			accessor: 'fullName'
		},
		{
			Header: t('adminUsers.email_address'),
			accessor: 'email',
		},
		{
			Header: t('adminUsers.user_type'),
			accessor: 'userTypeCode',
		},
		{
			Header: t('users.role_name'),
			accessor: 'roleName',
		},
		{
			Header: t('adminUsers.status'),
			accessor: 'isActive',
			Cell: setStatusCellValue,
			filterMethod: filterByStatus('isActive'),
			Filter: StatusFilterMethod
		},
		{
			accessor: 'Actions',
			Cell: (rowInfo: RowInfo) =>
				<div className="d-flex w-100">
					<ActionIcon title="Edit" icon={IconEdit} onClick={() => { openAdminlUser(rowInfo); }} />
				</div>,
			Header: '',
			showClearButton: true,
			id: 'Actions'
		},

	];

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<Page className="bg-light px-4" title={t('schools.school_user_list')}>
			<PageHero title={t('adminUsers.admin_users')}>
				<div className="d-flex justify-content-between">
					<div className="d-flex justify-content-between">
						<Button onClick={newUser} className="btn-primary">
							<IconAdd />
							{t('adminUsers.add_admin_user')}
						</Button>
					</div>
				</div>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={schoolUserList}
					columns={columns}
					resizable={true}
					sortable={true}
					filterable={true}
					noDataText={t('users.no_users_found')}
					loading={loading}
				/>
			</div>
		</Page>
	);

};

export default UsersList;