import DataTable from '@punchcard/core/components/DataTable';
import { IconArrowRight } from '@punchcard/core/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RowInfo } from 'react-table';

interface ITransfer {
	transferHistory: UserTransferDTO[]
}

const TransferHistory = (props: ITransfer) => {
	const { t } = useTranslation();
	const columns = [
		{
			Header: t('transfer.transfer_date'),
			accessor: 'transferredToTenantDate',

			Cell: (rowInfo: RowInfo) => {
				const dt = DateTime.fromISO(rowInfo.original.transferredToTenantDate);
				return DateTime.fromISO(dt?.toString()).toFormat('MM-dd-yyyy');
			},
		},
		{
			Header: t('transfer.previous_school'),
			accessor: 'oldSchoolName',
		},
		{
			Header: '',
			sortBy: false,
			accessor: 'newSchoolName',
			width: 70,
			Cell: () => {
				return <IconArrowRight />;
			},
		},
		{
			Header: t('transfer.new_school'),
			accessor: 'newSchoolName',
		},
	];

	return (
		<React.Fragment>
			<style>
				{`
				.ReactTable .rt-thead .rt-th:not(:last-child) div:first-child::after, .ReactTable .rt-thead .rt-td:not(:last-child) div:first-child::after {
					background-image: none !important;
				}
				`}
			</style>
			<p className="label form-label">{t('transfer.transfer_history')}</p>
			<DataTable
				data={props.transferHistory}
				columns={columns}
				resizable={false}
				sortable={false}
				showPagination={false}
				noDataText="No transfer items found."
				hasActions={false}
			/>
		</React.Fragment>

	);
};


export default TransferHistory;