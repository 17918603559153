import React from 'react';
import { Page, PageHero, Button, CheckboxGroup, Input, FormGroup, TextArea, LoadingPage } from '@punchcard/core';
import RoleHierarchy from '@punchcard/core/components/RoleHierarchy';
import { useParams, useNavigate } from 'react-router-dom';
import coreAPI from 'api/coreAPI';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface IRoleForm {
	id: number;
	name: string;
	description: string;
	permissionsGroup: string[];
	roles: RoleDTO[];
}

const RoleDetailsPage = () => {
	const roleId = parseInt(useParams().roleId ?? '0');
	const navigate = useNavigate();
	const [permissions, setPermissions] = React.useState<PermissionDTO[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { t } = useTranslation();
	const pageTitle = roleId === 0 ? t('roles.add_role') : t('roles.edit_role');
	const { handleSubmit, control, getValues, setValue, reset, watch } = useForm<IRoleForm>({
		defaultValues: {
			id: -1,
			name: '',
			description: '',
			roles: [],
			//permissionsGroup: [],
		},
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});
	const currentRoleId = watch('id');
	const currentRoleName = watch('name');
	const permissionOptions = permissions.map((permission) => {
		return { label: permission.name!, value: permission.id!.toString(), CategoryName: permission.categoryName };
	});

	React.useEffect(() => {
		const roles = getValues('roles');
		if (roles) {
			const updatedRoleList = roles.map((role: RoleDTO) =>
				role.id === currentRoleId ? { ...role, name: currentRoleName } : role
			);
			// Check if currentRole is already in the roles array
			const roleExists = roles.some((role: RoleDTO) => role.id === currentRoleId);
			// If currentRole does not exist in the array, add it
			if (!roleExists) {
				const newRole: RoleDTO = {
					id: -1,
					name: currentRoleName === '' ? 'New Role' : currentRoleName,
					roleHierarchy: 0,
					description: 'description', //currentRole.description,
					isDefault: false,
					permissionIds: [],
				};
				updatedRoleList.push(newRole); // Add currentRole to the array
				const newRoleList = updatedRoleList.map((role: RoleDTO) => ({
					// push all hierchy values up by 1, so there is no zero hierarchy.  This prevents a role from being saved with a zero hierarchy, which would mess up the next new role.
					...role,
					roleHierarchy: (role.roleHierarchy ?? 0) + 1,
				}));
				setValue('roles', newRoleList);
			} else {
				setValue('roles', updatedRoleList);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentRoleName]);

	React.useEffect(() => {
		async function fetchData() {
			setIsLoading(true);
			const permissions = await coreAPI.getPermissions();
			setPermissions(permissions);
			const rolelist = await coreAPI.getRoles();
			const sortedRoles =
				rolelist.filter(z => z.name !== 'Global Admin' && z.id !== currentRoleId)  // filter out the current role, as the current role is added in the RoleHierchy component
					.sort((a, b) => {
						const hierarchyA = a.roleHierarchy ?? Infinity;
						const hierarchyB = b.roleHierarchy ?? Infinity;
						return hierarchyB - hierarchyA;
					});
			const role = await coreAPI.getRole({ id: roleId });
			const roleForm: IRoleForm = {
				id: role.id ?? -1,
				name: role.name ?? '',
				description: role.description ?? '',
				roles: sortedRoles,
				permissionsGroup: permissions.filter(permission => role && role.permissionIds.some(id => id === permission.id))
					.map(permission => permission.id!.toString())
			};
			reset(roleForm);
			setIsLoading(false);
		}
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleId]);

	async function onRoleSave(roleData: IRoleForm) {
		setIsLoading(true);
		const permissionIdsSelected: number[] = roleData.permissionsGroup.map(permissionIdSelected => parseInt(permissionIdSelected));
		const updatedRole: PostRoleRequest = {
			id: roleId,
			name: roleData.name,
			description: roleData.description,
			roles: roleData.roles,
			permissionIds: permissionIdsSelected,
		};
		await coreAPI.postRole(updatedRole);
		setIsLoading(false);
		navigate('/admin/roles');
		toast.success(t('roles.role_updated'));
		navigate('/admin/roles');
	}

	function onCancelClick() {
		navigate('/admin/roles');
	}

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<Page className="bg-light p-3 ps-4" title={pageTitle}>
			<PageHero title={t('roles.role_details')} parentRoute="/admin/roles" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-6">
						<Controller
							control={control}
							name="name"
							rules={{
								required: t('this_field_is_required')
							}}
							render={({ field, fieldState }) =>
								<FormGroup label="Name" field={field} fieldState={fieldState} required={true}>
									<Input
										{...field}
										error={!!fieldState.error?.message}
									/>
								</FormGroup>
							}
						/>

						<Controller
							control={control}
							name="description"
							rules={{ required: t('this_field_is_required') }}
							render={({ field, fieldState }) =>
								<FormGroup label="Description" field={field} fieldState={fieldState} required={true}>
									<TextArea
										{...field}
										error={!!fieldState.error?.message}
										ref={null}
									/>
								</FormGroup>
							}
						/>

						<Controller
							control={control}
							name="permissionsGroup"
							render={({ field, fieldState }) => {
								const groupedPermissions = permissionOptions.reduce((acc: { [key: string]: typeof permissionOptions }, option) => {
									const { CategoryName } = option;
									if (!acc[CategoryName]) {
										acc[CategoryName] = [];
									}
									acc[CategoryName].push(option);
									return acc;
								}, {});

								return (
									<div className="">
										{Object.keys(groupedPermissions).map((category) => (
											<div key={category} className="bg-white p-3 mb-3 rounded-3 border border-gray-400">
												<FormGroup key={category} label={category === 'undefined' ? 'Other' : category} field={field} fieldState={fieldState}>
													<CheckboxGroup
														onChange={field.onChange}
														value={field.value}
														options={groupedPermissions[category]} // Options specific to this category
														ref={null}
													/>
												</FormGroup>
											</div>
										))}
									</div>
								);
							}}
						/>
						<Controller
							control={control}
							name="roles"
							render={({ field, fieldState }) => {
								return (
									<div className="">
										<FormGroup label={t('roles.role_hierarchy')} field={field} fieldState={fieldState}>
											<RoleHierarchy
												field={field}
												fieldState={fieldState}
												currentRole={{ name: currentRoleName, id: currentRoleId } as IRoleForm}
											/>
										</FormGroup>
									</div>
								);
							}}
						/>
					</div>
				</div>
				<div className="mt-2">
					<Button
						className="btn-outline-primary me-2"
						onClick={onCancelClick}
					>
						{t('cancel')}
					</Button>
					<Button
						className="btn-primary"
						disabled={isLoading}
						onClick={handleSubmit(onRoleSave)}
					>
						{t('navigation.save')}
					</Button>
				</div>
			</div>
		</Page>
	);
};

export default RoleDetailsPage;
