import React from 'react';
import { Button } from '@punchcard/core';
import Modal from 'react-modal';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface IProps {
	className?: string;
	headerChildren?: React.ReactNode;
	footerChildren?: React.ReactNode;
	confirmButtonChildren?: React.ReactNode;
	cancelButtonChildren?: React.ReactNode;
	confirmButtonLink?: string;
	cancelButtonLink?: string;
	confirmButtonAction?: () => void;
	cancelButtonAction?: () => void;
	isOpen: boolean;
	onRequestClose?: () => void;
	hideCloseButton?: boolean;
	modalSize?: 'xl' | 'lg' | 'md' | 'sm';
	shouldCloseOnOverlayClick?: boolean;
	modalPosition?: 'left' | 'right';
	loading?: boolean;
	disableConfirmButton?: boolean;
	footer?: boolean;
}
Modal.setAppElement('#root');
const ModalOverlay = (props: React.PropsWithChildren<IProps>) => {

	const {
		className,
		isOpen,
		children,
		onRequestClose,
		hideCloseButton,
		headerChildren,
		footerChildren,
		cancelButtonChildren,
		cancelButtonAction,
		cancelButtonLink,
		confirmButtonChildren,
		confirmButtonAction,
		confirmButtonLink,
		shouldCloseOnOverlayClick,
		modalSize,
		modalPosition,
		footer = false,
	} = props;
	const { t } = useTranslation();
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			className={{
				base: classNames('modal-dialog', `modal-${modalSize}`, modalPosition && `modal-${modalPosition}`),
				afterOpen: '',
				beforeClose: ''
			}}
			overlayClassName={{
				base: classNames('modal', className),
				afterOpen: 'show',
				beforeClose: 'hide'
			}}
			closeTimeoutMS={200}
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick ?? true}
			role="dialog"
		>
			<div className={classNames('modal-content', modalPosition && `modal-height-100 modal-${modalPosition}-border-radius-0`)} role="document">
				{!hideCloseButton && (
					<button type="button" className="modal-close p-2 d-flex align-items-center me-1" aria-label="Close" onClick={onRequestClose}>
						<span className="h6 text-decoration-underline pt-1 me-1">{t('close')}</span>
						<span aria-hidden="true">&times;</span>
					</button>
				)}
				{headerChildren && <div className="modal-header font-weight-bold h4">{headerChildren}</div>}
				<div className="modal-body">{children}</div>
				{(footerChildren || cancelButtonChildren || confirmButtonChildren) && (
					<div className={classNames('modal-footer', footer && 'border-top p-2')}>
						{cancelButtonChildren && (
							<Button to={cancelButtonLink} onClick={cancelButtonAction} className="btn-ghost-primary">
								{cancelButtonChildren}
							</Button>
						)}
						{confirmButtonChildren && (
							<Button to={confirmButtonLink} loading={props.loading} disabled={props.disableConfirmButton} onClick={confirmButtonAction} className="btn-primary ms-3">
								{confirmButtonChildren}
							</Button>
						)}
						{footerChildren}
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ModalOverlay;
