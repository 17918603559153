import React, { useMemo } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { ReactSketchCanvas, ReactSketchCanvasRef, CanvasPath, ReactSketchCanvasProps } from 'react-sketch-canvas';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Button, PDFReaderButtons } from '@punchcard/core';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import GradeModal from './GradeModal';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.js',
	import.meta.url,
).toString();

const resizeObserverOptions = {};
const maxWidth = 800;
interface IProps {
	pdfData: File;
	documentLoadSuccess: () => void;
	submitDrawing?: () => void;
	handleCanvasChange?: (path: CanvasPath, index: number) => void;
	isTeacherView?: boolean;
	currentActivity?: currentActivityAssessment;
	statusCodeMappingActivity: statusCodeMappingActivity;
	onStatusChange?: (courseActivityId: number | undefined, status: string, type: string | undefined, gradeReceived: number | undefined) => void;
	skipActivity?: (courseActivityId: number) => void;
	isActivityOpen: boolean;
	setIsDirty?: (isDirty: boolean) => void;

}


const PDFReader = (props: IProps) => {
	const { t } = useTranslation();
	const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(null);
	const [numPages, setNumPages] = React.useState<number>();
	const [containerWidth, setContainerWidth] = React.useState<number>();
	const [mode, setUpdateMode] = React.useState<'pencil' | 'eraser' | 'hand'>('pencil');
	const [colour, setColour] = React.useState<string>('#004959');
	const [size, setSize] = React.useState<number>(1);
	const [gradeModalOpen, setGradeModalOpen] = React.useState<boolean>(false);
	const [eraserSize, setEraserSize] = React.useState<number>(10);
	const [pageNumberArray, setPageNumberArray] = React.useState<number[]>(parseNumberSeries(props.currentActivity?.item?.pageNumbers));
	const [activity, setActivity] = React.useState<currentActivityAssessment | undefined>(props?.currentActivity);
	const methods = useFormContext<WIPForm>();

	const { control } = methods;
	const onResize = React.useCallback<ResizeObserverCallback>((entries) => {
		const [entry] = entries;
		if (entry) {
			setContainerWidth(entry.contentRect.width);
		}
	}, []);

	const saveDrawing = (submit: boolean) => {
		if (activity) {
			const updatedActivity = {
				...activity,
				item: {
					...activity.item,
					courseActivityStatus: {
						...activity?.item?.courseActivityStatus,
						statusCode: submit ? 'ReadyForReview' : 'InProgress',
					},
				},
			};
			setActivity(updatedActivity);
		}
		props.submitDrawing && props.submitDrawing();
	};
	useResizeObserver(containerRef, resizeObserverOptions, onResize);
	const options = useMemo(() => {
		return {
			cMapUrl: '/cmaps/',
			standardFontDataUrl: '/standard_fonts/',
		};
	}, []);
	const addHistory = (index: number) => {
		if (mode === 'pencil' && props.isActivityOpen) {
			const { setValue, getValues } = methods;
			const { undoHistory, undoIndex } = getValues();
			if (undoIndex !== null) {
				const removeOldHistory = undoHistory.slice(undoIndex);
				setValue('undoHistory', [index, ...removeOldHistory]); // tihs is in reverse on purpose.
				setValue('undoIndex', null);
			} else {
				setValue('undoHistory', [index, ...undoHistory]); // tihs is in reverse on purpose.
			}
			if (props.setIsDirty) {
				props.setIsDirty(true);
			}
		}
	};

	const touchStart = React.useRef({ x: 0, y: 0 });

	// Pointer down to capture the start point
	const handlePointerDown = (event: React.PointerEvent<HTMLDivElement>) => {
		//check if current target is an anchor
		if (event.target instanceof HTMLAnchorElement) {
			return;
		}
		if (mode === 'hand') {
			touchStart.current = { x: event.clientX, y: event.clientY };
			event.currentTarget.classList.add('is-scrolling');
			event.currentTarget.classList.add('cursor-grabbing');
			event.currentTarget.classList.remove('cursor-grab');
		}
	};
	const handlePointerUp = (event: React.PointerEvent<HTMLDivElement>) => {
		if (mode === 'hand') {
			event.currentTarget.classList.remove('is-scrolling');
			event.currentTarget.classList.add('cursor-grab');
			event.currentTarget.classList.remove('cursor-grabbing');
		}
	};


	// Pointer move to scroll based on the delta of the touch movement
	const handlePointerMove = (event: React.PointerEvent<HTMLDivElement>) => {
		if (mode === 'hand' && event.currentTarget.classList.contains('is-scrolling')) {
			const deltaX = event.clientX - touchStart.current.x;
			const deltaY = event.clientY - touchStart.current.y;

			// Reset touchStart to current touch point to create a smooth scroll effect
			touchStart.current = { x: event.clientX, y: event.clientY };

			// Adjust the scroll position of the PDF container
			const pdfContainer = document.getElementById('pdf-container'); // Adjust this selector as needed
			if (pdfContainer) {
				pdfContainer.scrollLeft -= deltaX;
				pdfContainer.scrollTop -= deltaY;
			}
		}
	};

	return (
		<FormProvider {...methods}>
			<PDFReaderButtons
				updateMode={setUpdateMode}
				mode={mode}
				isTeacherView={props.isTeacherView}
				currentActivity={activity}
				statusCodeMappingActivity={props.statusCodeMappingActivity}
				onStatusChange={props.onStatusChange}
				setGradeModalOpen={setGradeModalOpen}
				gradeModalOpen={gradeModalOpen}
			/>
			<div style={{ position: 'relative' }} className="d-flex flex-column flex-grow-1 overflow-y-auto">
				{gradeModalOpen &&
					<GradeModal
						setShowGradeModal={setGradeModalOpen}
						currentActivity={activity}
						onStatusChange={props.onStatusChange}
					/>
				}
				<div className={classNames('flex-column flex-grow-1 overflow-y-auto')}
					id="pdf-container">

					<Document file={props.pdfData} onLoadSuccess={onDocumentLoadSuccess} options={options}>

						{Array.from(new Array(numPages), (_el, index) => (
							pageNumberArray && pageNumberArray.includes(index + 1) &&
							<Controller
								name={`studentLayer.${index}.paths`}
								control={control}
								key={`studentlayer_${index}`}
								render={({ field }) => {
									return (
										// eslint-disable-next-line jsx-a11y/no-static-element-interactions
										<div
											key={`page_${index + 1}`}
											className={classNames('position-relative h-100 w-100', mode === 'hand' ? 'cursor-grab' : 'prevent-select')}
											onPointerDown={handlePointerDown}
											onPointerMove={handlePointerMove}
											onMouseDown={handlePointerDown}
											onPointerUp={handlePointerUp}
											onPointerCancel={handlePointerUp}
											onPointerLeave={handlePointerUp}
										>
											<Page
												key={`page_${index + 1}`}
												width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
												pageNumber={index + 1}
											/>
											<Canvas
												className="position-absolute top-0 left-0 w-100 h-100 right-0 bottom-0"
												width="100%"
												id={`canvas-${index}`}
												height="100%"
												value={field.value ?? []}
												allowOnlyPointerType="all"
												strokeWidth={size}
												// transparent stroke color for teacher view or when submitted so no changes are made to student layer
												strokeColor={colour}
												canvasColor="transparent"
												eraserWidth={eraserSize}
												readOnly={props.isTeacherView || !props.isActivityOpen || activity?.item?.courseActivityStatus?.statusCode === 'ReadyForReview' || mode === 'hand'}
												mode={mode}
												index={index}
												onChange={(v) => {
													field.onChange(v);
													addHistory(index);
												}}
												style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000 }}
											/>
										</div>
									);
								}} />
						))}
					</Document>
					{activity && props.isActivityOpen && !props.isTeacherView &&
						<div className="p-3">
							<h5 className="pb-2">{props.currentActivity?.type === 'Activity' ? t('wip.is_your_work_complete') : t('wip.is_your_assessment_complete')}</h5>
							<div className="d-flex justify-content-between flex-fill">
								<div className="d-flex align-items-center  w-100">
									{props.currentActivity?.type === 'Activity' &&
										<Button className="btn-outline-primary me-2" onClick={() => activity && props.skipActivity && props.skipActivity(activity?.item?.id)} >{t('wip.skip')}</Button>
									}
									<Button className="btn-primary" onClick={() => saveDrawing(true)} >
										{props.currentActivity?.type === 'Activity' ? t('wip.submit_next_activty') : t('yes')}
									</Button>
								</div>
							</div>
						</div>
					}
				</div>

			</div>
		</FormProvider>
	);

	function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
		setNumPages(nextNumPages);
		props.documentLoadSuccess && props.documentLoadSuccess();
	}
};

interface CanvasProps extends ReactSketchCanvasProps {
	mode?: 'pencil' | 'eraser' | 'hand';
	index: number;
}
const Canvas = (props: CanvasProps) => {
	const { mode, index, ...otherProps } = props;
	const ref = React.useRef<ReactSketchCanvasRef>(null);
	const { watch, getValues } = useFormContext<WIPForm>();
	const [undoIndex, lastAction] = watch(['undoIndex', 'lastAction']);

	React.useEffect(() => {
		if (mode) {
			ref.current?.eraseMode(mode === 'eraser');
		}
	}, [mode]);

	React.useEffect(() => {
		const { undoHistory } = getValues();
		if (undoIndex !== null && (undoHistory[undoIndex] === index) && lastAction === 'undo') {
			ref.current?.undo();
		}
		if (undoIndex !== null && (undoHistory[undoIndex + 1] === index) && lastAction === 'redo') {
			ref.current?.redo();
		}
	}, [undoIndex, lastAction, getValues, index]);

	return (
		<ReactSketchCanvas
			ref={ref}
			{...otherProps}
		/>
	);
};

function parseNumberSeries(input: string | undefined): number[] {
	if (input) {
		const segments = input.split(',');
		const result: number[] = [];
		for (const segment of segments) {
			if (segment.includes('-')) {
				const [start, end] = segment.split('-').map(Number);
				if (start <= end) {
					for (let i = start; i <= end; i++) {
						result.push(i);
					}
				} else {
					throw new Error(`Invalid range: ${segment}`);
				}
			} else {
				result.push(Number(segment));
			}
		}
		return result;
	}
	return [];
}

export default PDFReader;
