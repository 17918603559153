import React, { useState } from 'react';
import { Page, DataTable, PageHero, ActionIcon, Button, LoadingPage } from '@punchcard/core';
import coreAPI from 'api/coreAPI';
import useEffectAsync from 'components/customHooks/useEffectAsync';
import { useNavigate } from 'react-router-dom';
import { RowInfo } from 'react-table';
import { IconArrowRight } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';

const RoleListingPage = () => {
	const { t } = useTranslation();
	const [roles, setRoles] = useState<RoleDTO[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffectAsync(async (isMounted: any) => {
		setLoading(true);
		const roleData = await coreAPI.getRoles();
		if (isMounted()) {
			setRoles(roleData);
			setLoading(false);
		}
	});

	const columns = [
		{
			Header: 'Name',
			accessor: 'name',
			filterable: true,
		},
		{
			Header: 'Description',
			accessor: 'description',
			filterable: true,
		},
		{
			accessor: 'Actions',
			Cell: (row: RowInfo) => (
				<ActionIcon title="Details" icon={IconArrowRight} onClick={() => navigate(`/admin/roles/${row.original.id}`)} />
			),
			showClearButton: true,
			Header: 'Actions',
			id: 'Actions',
		}
	];

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<Page className="bg-light px-4" title={t('roles.roles')}>
			<PageHero title="Roles">
				<Button
					className="btn-primary"
					to="/admin/roles/-1"
				>
					New Role
				</Button>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={roles}
					columns={columns}
					filterable={true}
					noDataText="No roles found"
				/>
			</div>
		</Page>
	);
};

export default RoleListingPage;