/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Ref } from 'react';
import { ActionMeta, default as ReactSelect, GroupBase, OptionsOrGroups, Props as ReactSelectProps, SelectInstance } from 'react-select';

interface IProps extends React.PropsWithChildren<ReactSelectProps> {
	error?: boolean;
	ref?: Ref<SelectInstance<unknown, boolean, GroupBase<unknown>>> | null;
}

export const Select = React.forwardRef<any, IProps>((props, ref) => {
	const { options, value, isMulti, onChange, error, ...otherProps } = props;
	const isShowSelectedOptions = props.isMulti && !props.hideSelectedOptions;
	return (
		<ReactSelect
			isMulti={isMulti || false}
			options={options}
			value={getValue(value, options)}
			onChange={onChangeVal}
			ref={ref}
			styles={{
				control: (styles: any, { isFocused }) => ({
					...styles,
					borderColor: error ? '#F15D1E' : '#BDB3AD',
					boxShadow: error && isFocused ? '0 0 0 0.2rem #FBD6CC' : isFocused ? '0 0 0 0.2rem #F8EDCC' : 'none'
				}),
				option: (styles: any, { isFocused, isSelected, isDisabled }) => ({
					...styles,
					backgroundColor: isSelected ? isShowSelectedOptions ? 'transparent' : '#E9CA66' : isFocused ? '#F8EDCC' : '#FFFFFF',
					fontWeight: isSelected ? 'bold' : 'normal',
					color: '#1E1D1D',
					':active': {
						...styles[':active'],
						backgroundColor: !isDisabled
							? isSelected
								? '#E9CA66'
								: '#F1DC99'
							: undefined,
						fontWeight: 'bold',
					},
				}),
				indicatorSeparator: styles => ({
					...styles,
					marginTop: 0,
					marginBottom: 0,
				}),
				indicatorsContainer: styles => ({
					...styles,
					'svg': {
						fill: '#203040',
					},
				}),
				valueContainer: styles => ({
					...styles,
					maxHeight: '150px',
					overflowY: 'auto',
					flexWrap: 'wrap',
				}),
				multiValue: styles => ({
					...styles,
					backgroundColor: '#F8EDCC',
				}),
			}}
			{...otherProps}
		/>
	);

	function getValue(value: unknown, options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined) {
		if (Array.isArray(value)) {
			const val = options?.filter((v: any) => value.findIndex((j: any) => j === v.value) !== -1);
			return val;
		}
		if (typeof value === 'string') {
			const val = options?.filter((v: any) => v.value === value);

			return val;
		}
		return undefined;
	}

	function onChangeVal(newValue: unknown, actionMeta: ActionMeta<unknown>) {
		if (onChange === undefined) {
			return;
		}
		if (newValue && Array.isArray(newValue)) {
			const value = newValue.map(v => v.value);
			onChange(value, actionMeta);
			return;
		}
		if (newValue && Object.keys(newValue).includes('value')) {
			const thewNewValue = newValue as { value: any; label: any; };
			const value = thewNewValue.value!;
			onChange(value, actionMeta);
			return;
		}
		onChange(undefined, actionMeta);
		return undefined;
	}
});