import React from 'react';
import { Button, DataTable, Page, PageHero, ActionIcon, StatusFilterMethod, setStatusCellValue, filterByStatus, LoadingScreen } from '@punchcard/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import { IconAdd, IconArrowLeft, IconBuilding, IconEdit, IconPerson } from '@punchcard/core/icons';
import { RowInfo } from 'react-table';

const SchoolListPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const [schoolList, setSchoolList] = React.useState<AdminSchoolDTO[]>([]);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [networkName, setNetworkName] = React.useState<string>('');
	const { networkId } = useParams();

	React.useEffect(() => {
		async function getSchoolList() {
			const network = await adminAPI.school.getSchoolList(networkId ?? '0');
			if (network !== null) {
				setNetworkName(network.networkName);
				setSchoolList(network.schoolList);
			} else {
				toast.error(t('unable_to_retrieve_school_list'));
			}
			setLoading(false);
		}
		getSchoolList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const openSites = (row: RowInfo) => {
		navigate(`${row.original.id}/sites`);
	};
	const openSchool = (row: RowInfo) => {
		navigate(`${row.original.id}${location.search}`);
	};
	const openSchoolUsers = (row: RowInfo) => {
		navigate(`${row.original.id}/users`);
	};
	const newSchool = async () => {
		navigate('0');
	};

	const columns = [
		{
			Header: 'School ID',
			accessor: 'id',
			show: false
		},
		{
			Header: t('schools.school_name'),
			accessor: 'schoolName'
		},
		{
			Header: t('schools.school_timezone'),
			accessor: 'schoolTimeZone'
		},
		{
			Header: t('schools.network_name'),
			accessor: 'networkName'
		},
		{
			Header: t('schools.school_status'),
			accessor: 'isActive',
			Cell: setStatusCellValue,
			filterMethod: filterByStatus('isActive'),
			Filter: StatusFilterMethod
		},
		{
			accessor: 'Actions',
			width: 140,
			Cell: (rowInfo: RowInfo) =>
				<React.Fragment>
					<ActionIcon title="Sites" icon={IconBuilding} onClick={() => { openSites(rowInfo); }} />
					<ActionIcon title="Users" icon={IconPerson} onClick={() => { openSchoolUsers(rowInfo); }} />
					<ActionIcon title="Edit" icon={IconEdit} onClick={() => { openSchool(rowInfo); }} />
				</React.Fragment>,
			Header: '',
			showClearButton: true,
			id: 'Actions'
		},

	];
	if (loading) {
		return <LoadingScreen />;
	}
	return (
		<Page title={t('schools.school_list')} className="bg-light px-4">
			{networkId !== undefined &&
				<React.Fragment>
					<Button
						className="btn-ghost-primary ms-n3"
						icon={<IconArrowLeft />}
						onClick={() => navigate('/networks')}
					>
						{t('navigation.back')}
					</Button>
					<h6 className={'ms-3'}>{networkName}</h6>
				</React.Fragment>
			}
			<PageHero title={t('schools.school_list')} className="justify-content-between pt-0">
				<Button onClick={newSchool} className="btn-primary" icon={<IconAdd />}>{t('navigation.create_new')}</Button>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={schoolList}
					columns={columns}
					resizable={true}
					sortable={true}
					filterable={true}
					noDataText={t('schools.no_schools_found')}
					loading={loading}
				/>
			</div>
		</Page>
	);

};

export default SchoolListPage;