import React, { useEffect } from 'react';
import { Button, setStatusCellValue, filterByStatus, StatusFilterMethod, ActionIcon, DataTable, PageHero, Page, LoadingScreen } from '@punchcard/core';
import tenantAPI from 'api/tenantAPI';
import { useNavigate } from 'react-router-dom';
import { IconEdit, IconSchool } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
import { RowInfo } from 'react-table';

const TenantListPage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [tenants, setTenants] = React.useState<TenantListResponseDTO[]>([]);
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		async function getTenants() {
			setLoading(true);
			const tenants = await tenantAPI.getTenants();
			setTenants(tenants);
			setLoading(false);
		}
		getTenants();
	}, []);

	const tenantColumns = [
		{
			Header: 'Id ',
			accessor: 'id',
			show: false
		},
		{
			Header: 'Name',
			accessor: 'name',
			filterable: true
		},
		{
			Header: ' # Of Schools',
			accessor: 'nbOfSchools',
		},
		{
			Header: 'State',
			accessor: 'networkStateCode',
		},
		{
			Header: 'Status',
			accessor: 'isActive',
			Cell: setStatusCellValue,
			filterMethod: filterByStatus('isActive'),
			Filter: StatusFilterMethod
		},
		{
			Header: 'Actions',
			accessor: 'id',
			showClearButton: true,
			Cell: (row: RowInfo) => (
				<React.Fragment>
					<ActionIcon title="Manage Schools" icon={IconSchool} onClick={() => navigate(`/networks/${row.original.id}/schools`)} />
					<ActionIcon title="Edit network details" icon={IconEdit} onClick={() => navigate(`/networks/${row.original.id}`)} />
				</React.Fragment>
			)
		}
	];

	if (loading) {
		return <LoadingScreen />;
	}
	return (
		<Page title={t('schools.school_list')} className="bg-light px-4">
			<PageHero title="Networks">
				<Button className="btn-primary" to="/networks/0">
					{t('networks.add_network')}
				</Button>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={tenants}
					columns={tenantColumns}
					filterable={true}
					noDataText="No networks found"
				/>
			</div>
		</Page>
	);
};

export default TenantListPage;