import React from 'react';
import { Spinner } from '@punchcard/core';
import classNames from 'classnames';

interface IProps {
	className?: string;
}

const LoadingPage = (props: React.PropsWithChildren<IProps>) => {
	return (
		<div className="d-flex flex-fill align-items-center justify-content-center bg-light">
			<div className={classNames('spinner-container', props.className)}>
				<Spinner className="spinner-large" />
			</div>
			{props.children}
		</div>
	);
};

export default LoadingPage;