import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import logo from '@punchcard/core/assets/lyla-logo.png';
import { useMsal } from '@azure/msal-react';
import { Button } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import { IconLogout } from '@punchcard/core/icons';
import AdminMenuItems from './AdminMenuItems';
import AuthorizationWrapper from 'auth/AuthorizationWrapper';
import { Permissions } from 'auth/Permissions';

interface IProps {
	className?: string;
}

const NavBar = (props: IProps) => {
	const { t } = useTranslation();
	const { instance } = useMsal();
	const location = useLocation();
	const [showMenuAdmin, setShowMenuAdmin] = React.useState(false);
	return (
		<React.Fragment>
			<nav className={classNames('bg-light', 'navbar navbar-expand-lg navbar-dark shadow', props.className)}>
				<div className="container-fluid py-2 px-5">
					<div className="d-flex align-items-center">
						<Link to="/" className="navbar-brand">
							<img src={logo} alt="Punchcard" />
						</Link>
						<ul className="navbar-nav d-flex flex-row">
							<li className="nav-item">
								<Button
									to="/courses"
									className={classNames('btn-ghost-primary nav-link px-3 py-2', !location.pathname.startsWith('/courses') && 'not-active')}
								>
									{t('navbar.courses')}
								</Button>
							</li>
							<AuthorizationWrapper type="hide" permissions={[Permissions.ReadTenant, Permissions.WriteTenant]}>
								<li className="nav-item">
									<Button
										to="/networks"
										className={classNames('btn-ghost-primary nav-link px-3 py-2', !location.pathname.startsWith('/networks') && 'not-active')}
									>
										{t('navbar.networks')}
									</Button>
								</li>
							</AuthorizationWrapper>
							<li className="nav-item">
								<Button
									to="/schools"
									className={classNames('btn-ghost-primary nav-link px-3 py-2', !location.pathname.startsWith('/schools') && 'not-active')}
								>
									{t('navbar.schools')}
								</Button>
							</li>

							<AdminMenuItems
								title="Admin Tools"
								setShowMenuAdmin={setShowMenuAdmin}
								showMenuAdmin={showMenuAdmin}
							/>
						</ul>
					</div>
					<div className="bg-light">
						<ul className="navbar-nav ms-lg-auto">
							<li className="nav-item">
								<Button className="btn-outline-primary" onClick={() => instance.logoutRedirect()}>{t('logout')} <IconLogout className="ms-2" /></Button>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</React.Fragment>
	);
};

// export default withRouter(NavBar);
export default NavBar;